import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { ProductRequest, ProductRequestStatusEnum, WebcoffeeLinks } from '@webcoffee/interfaces';
import { EMPTY, Observable, catchError, switchMap, tap } from 'rxjs';
import { ToasterService } from '../../shared/utils/services/toastr.service';
import { Router } from '@angular/router';

export interface ProductRequestsState {
    productRequests: ProductRequest[];
    productRequest: ProductRequest | null;
}

@Injectable()
export class ProductRequestsStore extends ComponentStore<ProductRequestsState> {
    constructor(private http: HttpClient, private toastr: ToasterService, private router: Router) {
        super({
            productRequests: [],
            productRequest: null,
        });
    }

    readonly getProductRequests = this.select((state) => state.productRequests);
    readonly getProductRequest = this.select((state) => state.productRequest);

    readonly removeProduct = this.updater((state, id: number) => ({ ...state, productRequests: state.productRequests.filter((pr) => pr.id !== id) }));
    readonly markAsResolved = this.updater((state, id: number) => ({
        ...state,
        productRequests: state.productRequests.filter((pr) => (pr.id === id ? { ...pr, status: ProductRequestStatusEnum.Rezolvata } : pr)),
    }));

    readonly watchProductRequestIdEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            tap((id: string) => {
                if (id) {
                    if (this.get().productRequest?.id !== +id) {
                        this.getProductRequestEffect(id);
                    }
                }
            }),
        ),
    );

    readonly getProductRequestEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) =>
                this.http.get<ProductRequest>(`/api/product-requests/${id ?? this.get().productRequest.id}`).pipe(
                    tap((productRequest) => this.patchState({ productRequest })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly getProductRequestsEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get<ProductRequest[]>('/api/product-requests').pipe(
                    tap((productRequests) => this.patchState({ productRequests })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly deleteProductRequestEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) => {
                if (this.get().productRequest) {
                    return this.http.delete(`/api/product-requests/${this.get().productRequest?.id}`).pipe(
                        tap((_) => {
                            this.toastr.success('Cererea a fost stearsa cu succes');
                            this.removeProduct(this.get().productRequest.id);
                            this.patchState({ productRequest: null });
                            this.router.navigate([WebcoffeeLinks.productRequests]);
                        }),
                        catchError((err) => {
                            this.toastr.error(err.erroe.message);
                            return EMPTY;
                        }),
                    );
                }
                return EMPTY;
            }),
        ),
    );

    readonly markAsResolvedEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) => {
                if (this.get().productRequest) {
                    return this.http.post(`/api/product-requests/${this.get().productRequest?.id}/resolve`, {}).pipe(
                        tap((_) => {
                            this.toastr.success('Cererea a fost marcata ca rezolvata');
                            this.markAsResolved(this.get().productRequest.id);
                            this.patchState({ productRequest: null });
                            this.router.navigate([WebcoffeeLinks.productRequests]);
                        }),
                        catchError((err) => {
                            this.toastr.error(err.erroe.message);
                            return EMPTY;
                        }),
                    );
                }
                return EMPTY;
            }),
        ),
    );
}
